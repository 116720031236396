import { createSlice } from '@reduxjs/toolkit'
import { signInEmail, logoutUser, createUser, isLoggedIn } from "../api/UserApi";
import { history } from '../helpers/history';


const redirectUserLogic = (searchQuery: any) => {
  // Let's check the login intent and redirect to the right place.
  switch(searchQuery.a) {
    case 'list':
        // Users intent is to list a plate they have just designed.
        // Redirect the user to complete the listing.
        
        history.push('/listing/' + searchQuery.i);
        break;
    case 'plate':
        history.push(`/plate/${searchQuery.t}-${searchQuery.i}`);
        break;
    default:
        // Standard login.. take to user homepage ?
        history.push('/');
        break;
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    authenticated: false,
    loginPending: false,
    loginError: false,
    signupPending: false,
    signupError: ''
  },
  reducers: {
    loginUserStart: (state) => {
      state.loginPending = true
    },
    loginUserSuccess: (state, { payload }) => {
      state.loginPending = false;
      state.authenticated = true;

      // The user has logged in.
      return redirectUserLogic(payload.searchQuery);
    },
    loginUserSuccessSilent: (state) => {
      state.loginPending = false;
      state.authenticated = true;
    },
    loginUserFailure: (state) => {
      // Failed to login... invalid username or password.
      state.loginPending = false;
      state.authenticated = false;
      state.loginError = true;
    },
    signupUserStart: (state) => {
      state.signupPending = true;
    },
    signupUserSuccess: (state, { payload }) => {
      state.signupPending = false;
      state.authenticated = true;

      return redirectUserLogic(payload.searchQuery);
    },
    signupUserFailure: (state, { payload }) => {
      state.signupPending = false;
      state.signupError = payload.errMessage;
    },
    logoutUserSuccess: (state) => {
      state.authenticated = false;

      // Take user back to main page
      history.push('/');
    }
  },
})

// Action creators are generated for each case reducer function
export const { loginUserStart, loginUserSuccess, loginUserFailure, logoutUserSuccess, loginUserSuccessSilent, signupUserStart, signupUserFailure, signupUserSuccess } = userSlice.actions

export default userSlice.reducer

export function loginUser(username: string, password: string, searchQuery: any) {
  return async (dispatch: any) => {
    dispatch(loginUserStart())

    try {
      const loginRequest = await signInEmail(username, password);
      if(!loginRequest || !loginRequest.authenticated || loginRequest.err) return dispatch(loginUserFailure());

      dispatch(loginUserSuccess({
        searchQuery
      }));
    } catch (error) {
      dispatch(loginUserFailure())
    }
  }
}

export function logout() {
  return async (dispatch: any) => {
    try {
      await logoutUser();
      dispatch(logoutUserSuccess());
    } catch (error) {
      dispatch(logoutUserSuccess());
    }
  }
}

export function signupUser(email: string, password: string, confirmPassword: string, firstName: string, searchQuery: any) {
  return async (dispatch: any) => {
    // Validate password meets requirements.
    if(firstName.length < 2) return dispatch(signupUserFailure({ errMessage: 'Please provide a first name.' }));
    if(password.length < 8) return dispatch(signupUserFailure({ errMessage: 'Password must be at least 8 characters long.' }));  
    if(password !== confirmPassword) return dispatch(signupUserFailure({ errMessage: 'Passwords do not match.' }));

    // Attempt to create the user.
    const newUser = await createUser(email, firstName, password);
    if(!newUser) return dispatch(signupUserFailure({ errMessage: 'An error occurred when signing up. Please try again later.' }));

    if(newUser?.err) {
      // Error.
      if(newUser.code === 'signupFailedEm') return dispatch(signupUserFailure({ errMessage: 'Email address already in use.' }));
      return dispatch(signupUserFailure({ errMessage: 'An error occurred when signing up. Please try again later.' }));
    } 
    // User has now signed up, and is authenticated... store credentials.
    dispatch(signupUserSuccess({
      searchQuery
    }));
  };
}

export function alreadySignedInCheck() {
  return async (dispatch: any) => {
    const alreadySignedIn = await isLoggedIn();
    if(alreadySignedIn) {
      // User is already signed in.
      dispatch(loginUserSuccessSilent());
    }
  }
}
