import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MobileView, isBrowser } from 'react-device-detect';
import './Navbar.scss';
import { connect } from "react-redux";
import { alreadySignedInCheck } from "../slices/userSlice";

class Navbar extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            clicked: false,
            userLoggedIn: false
        }
    }

    async componentDidMount() {
        // Is the user loggedIn
        (this.props as any).alreadySignedInCheck();
    }

    render () {
        return <React.Fragment>
            <div className="Navbar">
                <div className="NavbarContainer">
                    <Link to="/" className="title" onClick={() => this.setState({clicked: false})}>AutoPlates.</Link>
                    {isBrowser && <div className="BrowserNavbarOptions">
                            <Link to="/" className="link" onClick={() => this.setState({clicked: false})}>Home</Link>
                            {(this.props as any).user.authenticated && <Link to="/manage" className="link" onClick={() => this.setState({clicked: false})}>Listings</Link>}
                            <Link to="/support" className="link" onClick={() => this.setState({clicked: false})}>Support</Link>
                            {(this.props as any).user.authenticated && <Link to="/logout" className="link" onClick={() => this.setState({clicked: false, userLoggedIn: false})}>Sign out</Link>}
                            {!(this.props as any).user.authenticated && <Link to="/login" className="link" onClick={() => this.setState({clicked: false})}>Sign in</Link>}
                            <Link to="/listing" className="link call" onClick={() => this.setState({clicked: false})}>Sell your Plate</Link>
                        </div>}
                    <MobileView viewClassName="bars">
                        <FontAwesomeIcon icon={faBars} className="bars" onClick={() => this.setState({clicked: !(this.state as any).clicked})}/>
                    </MobileView>
                </div>
            </div>
            {(this.state as any).clicked && <div className="NavbarOptions">
                <Link to="/" className="link" onClick={() => this.setState({clicked: false})}>Home</Link>
                {(this.props as any).user.authenticated && <Link to="/manage" className="link" onClick={() => this.setState({clicked: false})}>Listings</Link>}
                <Link to="/support" className="link" onClick={() => this.setState({clicked: false})}>Support</Link>
                {(this.props as any).user.authenticated && <Link to="/logout" className="link" onClick={() => this.setState({clicked: false, userLoggedIn: false})}>Sign out</Link>}
                {!(this.props as any).user.authenticated && <Link to="/login" className="link" onClick={() => this.setState({clicked: false})}>Sign in</Link>}
            </div>}
        </React.Fragment>
    }
}

const mapStateToProps = (state: any ) => ({ user: state.user })

const mapDispatchToProps = (dispatch: any) => {
    return {
        alreadySignedInCheck: () => dispatch(alreadySignedInCheck())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
