import {
  Router,
  Switch,
  Route
} from "react-router-dom";
import React, { Suspense } from 'react';
import Navbar from './components/Navbar';
import { history } from "./helpers/history";

const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));
const ResetPasswordRequest = React.lazy(() => import("./pages/ResetPasswordRequest"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Listing = React.lazy(() => import("./pages/Listing"));
const ListingFinal = React.lazy(() => import("./pages/ListingFinal"));
const PlateListing = React.lazy(() => import("./pages/PlateListing"));
const Search = React.lazy(() => import("./pages/Search"));
const ManageListings = React.lazy(() => import("./pages/ManageListings"));
const Support = React.lazy(() => import("./pages/Support"));
const Logout = React.lazy(() => import("./pages/Logout"));

function App() {
  return (
    <Router history={history}>
        <Navbar />
        <Suspense fallback={<div></div>}>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/listing" component={Listing} />
              <Route path="/listing/:plateId" component={ListingFinal} />
              <Route path="/login" component={Login} />
              <Route path="/join" component={Signup} />
              <Route exact path="/resetpassword" component={ResetPasswordRequest} />
              <Route path="/resetpassword/:key" component={ResetPassword} />
              <Route path="/plate/:plateText-:plateId" component={PlateListing} />
              {/* <Route path="/search" component={Search} /> */}
              <Route path="/manage" component={ManageListings} />
              <Route path="/support" component={Support} />
              <Route path="/logout" component={Logout} />
          </Switch>
          </Suspense>
    </Router>

  );
}

export default App;
