import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ConfigureErrorHandler } from './api/AuthInterceptor';
import LogRocket from 'logrocket';
import mixpanel from 'mixpanel-browser';
//import TagManager from 'react-gtm-module';
import store from './redux/store';
import { Provider } from 'react-redux';

if(process.env.NODE_ENV === 'production') {
  LogRocket.init('w6b5gm/autoplates');
  mixpanel.init('8379f578ebb9e86244abc160887f6bfc'); 
} else {
  // Development
  mixpanel.init('8379f578ebb9e86244abc160887f6bfc', {debug: true}); 
}

// TagManager.initialize({
//   gtmId: 'GTM-WKQFWNK'
// });


ConfigureErrorHandler(); // Axios Auth Inteceptors

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
