import axios from 'axios';
import { refreshTokens } from './UserApi';

export const ConfigureErrorHandler = () => {
    axios.defaults.withCredentials = true;
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;

            // Attempt to refresh tokens
            return refreshTokens().then(res => {
                if(res) return axios(originalRequest);
            });
        }
    });
}