import axios from 'axios';

export const signInEmail = async (email: string, password: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, password });
        return response.data;
    } catch (e) {
        return false;
    }
}

export const requestResetPassword = async (email: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/requestresetpassword`, { email });
        return response.data;
    } catch (e) {
        return false;
    }
}

export const resetPassword = async (resetkey: string, password: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/resetpassword`, { resetkey, password });
        return response.data;
    } catch (e) {
        return false;
    }
}

export const refreshTokens = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/refresh_token`);
        if(response?.data?.refreshed) return true;
        return false;
    } catch (e) {
        return false;
    }
}

export const createUser = async (email: string, firstname: string, password: string) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/signup`, { email, firstname, password });
        return response?.data;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export const isLoggedIn = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/me`);
        if(response?.data?.email) return true;
        return false;
    } catch (e) {
        return false;
    }
}

export const logoutUser = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`);
        return response.data;
    } catch (e) {
        return false;
    }
}
